<template>
  <wit-block-content-container class="cases-list-panel">
    <div class="panel-title">
      全部案例
    </div>
    <div class="panel w-l-rw-center-compact">
      <wit-case-panel class="case-panel" v-for="(item, index) in cases"
                      :key="index" :icon-src="item.icon"
                      :title="item.company"
                      :tags="item.caseTags"
                      :description="item.caseAbstract"></wit-case-panel>
    </div>

  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import WitCasePanel from "@/components/content/WitCasePanel/WitCasePanel";
  import {getCasesData} from "@/network/cases";
  export default {
    name: "CasesListPanel",
    components:{
      WitBlockContentContainer,
      WitCasePanel
    },
    created() {
      getCasesData().then(response => {
        this.cases = response
      })
    },
    updated() {
      this.$emit('ContentUpdated')
    },
    data(){
      return{
        cases:[]
      }
    }
  }
</script>

<style scoped>
  .cases-list-panel{
    width: 100%;
    margin: 100px 0;
  }
  .cases-list-panel .case-panel{
    width: 30%;
  }
  .cases-list-panel .panel-title{
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  .cases-list-panel .panel{
    width: 100%;
  }
</style>
