<template>
  <div class="wit-case-panel">
    <div class="content w-l-column-rs-compact">
      <div class="icon">
        <img :src="iconSrc" height="50px" alt="">
      </div>
      <div class="title font-24 ellipsis ellipsis-1">
        {{ title }}
      </div>
      <div class="tag-block w-l-row-cs-compact">
        <div class="tag-item font-12" v-for="(tag, index) in tags" :key="index">
          {{tag.tagName}}
        </div>
      </div>
      <div class="desc ellipsis ellipsis-2">
        {{description}}
      </div>
      <div class="detail-link font-16 fc-black w-l-row-cs-compact">
        <div class="text">
          阅读完整案例
        </div>
        <div class="right-icon font-bold">
          <i class="iconfont icon-right-arrow"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WitCasePanel",
    props:{
      iconSrc: String,
      title: String,
      tags: Array,
      description: String
    }
  }
</script>

<style scoped>
  .wit-case-panel{
    width: 400px;
    height: 400px;
    border: 1px solid #e4e4e4;
    margin: 10px;
    transition: 0.5s all;
  }
  .wit-case-panel:hover{
    box-shadow: 0 0 15px lightgray;
    transform: translateY(-2px);
  }
  .wit-case-panel .content{
    width: 100%;
    height: 100%;
    padding: 50px 40px;
  }
  .wit-case-panel .content>div{
    margin: 10px 0;
  }
  .wit-case-panel .icon{
    height: 50px;
  }
  .wit-case-panel .title{
    line-height: 1.4;
    color: #2b2b2b;
    margin-bottom: 5px !important;
  }
  .wit-case-panel .tag-block{
    margin: 5px 0 !important;
  }
  .wit-case-panel .tag-block .tag-item{
    border: 1px solid #cccccc;
    color: #969896;
    padding: 5px 10px;
    border-radius: 20px;
    line-height: 12px;
    margin: 2px;
  }
  .wit-case-panel .desc{
    line-height: 1.71;
    color: #7b7b7b;
    margin: 15px 0 !important;
  }
  .wit-case-panel .detail-link{
    cursor: pointer;
  }
  .wit-case-panel .detail-link:hover{
    color: var(--color-tint);
  }
  .wit-case-panel .detail-link .text{
    margin-right: 20px;
  }
  .wit-case-panel .detail-link .right-icon{
    transition: 0.5s all;
    color: var(--color-tint);
  }
  .wit-case-panel .detail-link:hover .right-icon{
    transform: translateX(10px);
  }
</style>
