<template>
  <div class="case">
    <el-main class="wit-case-container">
      <img-title-panel bg-img="/images/cases/case_title_bg.jpg" height="600px">
        <div class="page-title">精选服务案例</div>
        <div class="page-title-tip">沃丁科技为创造用户价值而奋斗，为更多客户提供新动力</div>
      </img-title-panel>
      <cases-list-panel @ContentUpdated="ContentUpdated"></cases-list-panel>
    </el-main>
  </div>
</template>

<script>
  import ImgTitlePanel from "@/components/content/ImgTitlePanel/ImgTitlePanel";
  import CasesListPanel from "@/views/case/comps/CasesListPanel/CasesListPanel";
  export default {
    name: "Case",
    components:{
      ImgTitlePanel,
      CasesListPanel
    },
    methods:{
      ContentUpdated(){
        this.$emit('ContentUpdated')
      }
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    }
  }
</script>

<style scoped>
  .case .wit-case-container{
    padding: 0 !important;
  }
  .case .wit-case-container .page-title{
    margin-bottom: 20px;
  }
</style>
